exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-dashboard-tsx": () => import("./../../../src/pages/dashboard.tsx" /* webpackChunkName: "component---src-pages-dashboard-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-onboard-tsx": () => import("./../../../src/pages/onboard.tsx" /* webpackChunkName: "component---src-pages-onboard-tsx" */),
  "component---src-pages-pricing-tsx": () => import("./../../../src/pages/pricing.tsx" /* webpackChunkName: "component---src-pages-pricing-tsx" */),
  "component---src-templates-blog-post-list-template-tsx": () => import("./../../../src/templates/blogPostListTemplate.tsx" /* webpackChunkName: "component---src-templates-blog-post-list-template-tsx" */),
  "component---src-templates-communication-post-template-tsx": () => import("./../../../src/templates/communicationPostTemplate.tsx" /* webpackChunkName: "component---src-templates-communication-post-template-tsx" */)
}

